<template>
  <div class="screen__wrapper">
    <div class="stats__table stats__table--second stats__table-ctr">
      
      <div class="stats__column stats__column-title">
        <div class="stats__row stats__row-title">Агент</div>
        <div class="stats__row stats__row-title stats__row--fifts">
          <span>Разом НЕД</span>
          <span>Було</span>
          <span class="stats__row--green">Нові<br>
            <small>
              (<span class="cursor--default" title="Створено">С</span>
              | <span class="cursor--default" title="Змінено">З</span>
              | <span class="cursor--default" title="Відновлено">В</span>)
            </small>
          </span>
          <span class="stats__row--red">Видалено<br>
            <small>
              (<span class="cursor--default" title="Змінено">З</span>
              |<span class="cursor--default" title="Архівовано">А</span>)
            </small>
          </span>
          <span class="stats__row--blue">Стало</span>
        </div>
        <div class="stats__row stats__row-title stats__row--fifts">
          <span>Разом ЕД</span>
          <span>Було</span>
          <span class="stats__row--green">Нові<br>
            <small>
              (<span class="cursor--default" title="Створено">С</span>
              | <span class="cursor--default" title="Змінено">З</span>
              | <span class="cursor--default" title="Відновлено">В</span>)
            </small>
          </span>
          <span class="stats__row--red">Видалено<br>
            <small>
              (<span class="cursor--default" title="Змінено">З</span>)
              |<span class="cursor--default" title="Архівовано">А</span>)
            </small>
          </span>
          <span class="stats__row--blue">Стало</span>
        </div>
      </div>

      <div class="stats__column stats__column--light">
        <div class="stats__row">Разом АН</div>
        <div class="stats__row stats__row--fifts">
          <span>{{ totals.initialNED + totals.addedNED }}</span>
          <span>{{ totals.initialNED }}</span>
          <span class="stats__row--green">
            {{ totals.addedNED }} ({{ totals.createdNED }} | {{ totals.editAddedNED }} | {{ totals.unarchivedNED }})
          </span>
          <span class="stats__row--red">
            {{ totals.deletedNED }} ({{ totals.editDeletedNED }} | {{ totals.archivedNED }})
          </span>
          <span class="stats__row--blue">{{ totals.initialNED + totals.addedNED - totals.deletedNED }}</span>
        </div>

        <div class="stats__row stats__row--fifts">
          <span>{{ totals.initialED + totals.addedED }}</span>
          <span>{{ totals.initialED }}</span>
          <span class="stats__row--green">
            {{ totals.addedED }} ({{ totals.createdED }} | {{ totals.editAddedED }} | {{ totals.unarchivedED }})
          </span>
          <span class="stats__row--red">
            {{ totals.deletedED }} ({{ totals.editDeletedED }} | {{ totals.archivedED }})
          </span>
          <span class="stats__row--blue">{{ totals.initialED + totals.addedED - totals.deletedED }}</span>
        </div>
      </div>

      <div class="stats__column" v-for="row of nonBlockedRows" :key="row.id" :class="{ 'stats__column--del': row.isBlocked }">
        <div class="stats__row">{{ row.name }}</div>
        <div class="stats__row stats__row--fifts">
          <span>{{ row.initialNED + row.addedNED }}</span>
          <span>{{ row.initialNED }}</span>
          <span class="stats__row--green">
            {{ row.addedNED }} ({{ row.createdNED }} | {{ row.editAddedNED }} | {{ row.unarchivedNED }})
          </span>
          <span class="stats__row--red">
            {{ row.deletedNED }} ({{ row.editDeletedNED }} | {{ row.archivedNED }})
          </span>
          <span class="stats__row--blue">{{ row.initialNED + row.addedNED - row.deletedNED }}</span>
        </div>

        <div class="stats__row stats__row--fifts">
          <span>{{ row.initialED + row.addedED }}</span>
          <span>{{ row.initialED }}</span>
          <span class="stats__row--green">
            {{ row.addedED }} ({{ row.createdED }} | {{ row.editAddedED }} | {{ row.unarchivedED }})
          </span>
          <span class="stats__row--red">
            {{ row.deletedED }} ({{ row.editDeletedED }} | {{ row.archivedED }})
          </span>
          <span class="stats__row--blue">{{ row.initialED + row.addedED - row.deletedED }}</span>
        </div>
      </div>

      <div v-if="blockedTotals" @click="isBlockedShown = !isBlockedShown" class="stats__column stats__column--del">
        <div class="stats__row">
          <i class="arrow" style="margin-right: 5px" :class="{ 'down': isBlockedShown, 'right': !isBlockedShown }"></i>
          Разом видалені
        </div>
        <div class="stats__row stats__row--fifts">
          <span>{{ blockedTotals.initialNED + blockedTotals.addedNED }}</span>
          <span>{{ blockedTotals.initialNED }}</span>
          <span class="stats__row--green">
            {{ blockedTotals.addedNED }} ({{ blockedTotals.createdNED }} | {{ blockedTotals.editAddedNED }} | {{ blockedTotals.unarchivedNED }})
          </span>
          <span class="stats__row--red">
            {{ blockedTotals.deletedNED }} ({{ blockedTotals.editDeletedNED }} | {{ blockedTotals.archivedNED }})
          </span>
          <span class="stats__row--blue">{{ blockedTotals.initialNED + blockedTotals.addedNED - blockedTotals.deletedNED }}</span>
        </div>

        <div class="stats__row stats__row--fifts">
          <span>{{ blockedTotals.initialED + blockedTotals.addedED }}</span>
          <span>{{ blockedTotals.initialED }}</span>
          <span class="stats__row--green">
            {{ blockedTotals.addedED }} ({{ blockedTotals.createdED }} | {{ blockedTotals.editAddedED }} | {{ blockedTotals.unarchivedED }})
          </span>
          <span class="stats__row--red">
            {{ blockedTotals.deletedED }} ({{ blockedTotals.editDeletedED }} | {{ blockedTotals.archivedED }})
          </span>
          <span class="stats__row--blue">{{ blockedTotals.initialED + blockedTotals.addedED - blockedTotals.deletedED }}</span>
        </div>
      </div>

      <div class="stats__column stats__column--del" v-if="isBlockedShown" v-for="row of blockedRows" :key="row.id">
        <div class="stats__row">{{ row.name }}</div>
        <div class="stats__row stats__row--fifts">
          <span>{{ row.initialNED + row.addedNED }}</span>
          <span>{{ row.initialNED }}</span>
          <span class="stats__row--green">
            {{ row.addedNED }} ({{ row.createdNED }} | {{ row.editAddedNED }} | {{ row.unarchivedNED }})
          </span>
          <span class="stats__row--red">
            {{ row.deletedNED }} ({{ row.editDeletedNED }} | {{ row.archivedNED }})
          </span>
          <span class="stats__row--blue">{{ row.initialNED + row.addedNED - row.deletedNED }}</span>
        </div>

        <div class="stats__row stats__row--fifts">
          <span>{{ row.initialED + row.addedED }}</span>
          <span>{{ row.initialED }}</span>
          <span class="stats__row--green">
            {{ row.addedED }} ({{ row.createdED }} | {{ row.editAddedED }} | {{ row.unarchivedED }})
          </span>
          <span class="stats__row--red">
            {{ row.deletedED }} ({{ row.editDeletedED }} | {{ row.archivedED }})
          </span>
          <span class="stats__row--blue">{{ row.initialED + row.addedED - row.deletedED }}</span>
        </div>
      </div>

    </div>
  </div>
</template>
  
<script>
import sharedMethods from '../Shared/sharedMethods'
export default {
  name: 'ContractStatistics',
  props: ['rows'],
  data() {
    return {
      isBlockedShown: false
    }
  },
  computed: {
    nonBlockedRows() {
      return this.rows.filter(r => !r.isBlocked)
    },
    blockedRows() {
      return this.rows.filter(r => r.isBlocked)
    },
    totals() {
      return sharedMethods.getContractTotals(this.rows)
    },
    blockedTotals() {
      return sharedMethods.getContractTotals(this.blockedRows)
    },
  },
}
</script>
  
<style lang="scss" scoped>
@import 'style.scss';
</style>
  