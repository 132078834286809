<template>
  <div class="screen__wrapper">
    <div class="stats__table">

      <div class="stats__column stats__column-title">
        <div class="stats__row stats__row-title">Агент</div>
        <div class="stats__row stats__row-title stats__row--three">
          <span>Разом</span>
          <span>Було</span>
          <span class="stats__row--green">Нові<br>
            <small>
              (<span class="cursor--default" title="Створено">С</span>
              | <span class="cursor--default" title="Змінено">З</span>
              | <span class="cursor--default" title="Відновлено">В</span>)
            </small>
          </span>
        </div>
        <div class="stats__row stats__row-title stats__row--red">Видалено<br>
          <small>
            (<span class="cursor--default" title="Змінено">З</span>
            | <span class="cursor--default" title="Архівовано">А</span>)
          </small>
        </div>
        <div class="stats__row stats__row-title stats__row--blue">Стало</div>
        <div class="stats__row stats__row-title stats__row--orange">+/-</div>
      </div>

      <div v-if="totals" class="stats__column stats__column--light">
        <div class="stats__row">Разом АН</div>
        <div class="stats__row stats__row--three">
          <span>{{ totals.initial + totals.added }}</span>
          <span>{{ totals.initial }}</span>
          <span class="stats__row--green">{{ totals.added }} ({{ totals.created }} | {{ totals.editAdded }} | {{ totals.unarchived }})</span>
        </div>
        <div class="stats__row stats__row--red">{{ totals.deleted }} ({{ totals.editDeleted }} | {{ totals.archived }})
        </div>
        <div class="stats__row stats__row--blue">{{ totals.initial + totals.added - totals.deleted }}</div>
        <div class="stats__row stats__row--orange">
          {{ totals.added > totals.deleted ? '+' : '' }}{{ totals.added - totals.deleted }}
        </div>
      </div>

      <div class="stats__column" v-for="row of nonBlockedRows" :key="row.id">
        <div class="stats__row">{{ row.name }}</div>
        <div class="stats__row stats__row--three">
          <span>{{ row.initial + row.added }}</span>
          <span>{{ row.initial }}</span>
          <span class="stats__row--green">{{ row.added }} ({{ row.created }} | {{ row.editAdded }} | {{ row.unarchived }})</span>
        </div>

        <div class="stats__row stats__row--red">{{ row.deleted }} ({{ row.editDeleted }} | {{ row.archived }})</div>
        <div class="stats__row stats__row--blue">{{ row.initial + row.added - row.deleted }}</div>
        <div class="stats__row stats__row--orange">
          {{ row.added > row.deleted ? '+' : '' }}{{ row.added - row.deleted }}
        </div>
      </div>

      <div v-if="blockedTotals" @click="isBlockedShown = !isBlockedShown" class="stats__column stats__column--del">
        <div class="stats__row">
          <i class="arrow" style="margin-right: 5px" :class="{ 'down': isBlockedShown, 'right': !isBlockedShown }"></i>
          Разом видалені
        </div>
        <div class="stats__row stats__row--three">
          <span>{{ blockedTotals.initial + blockedTotals.added }}</span>
          <span>{{ blockedTotals.initial }}</span>
          <span class="stats__row--green">{{ blockedTotals.added }} ({{ blockedTotals.created }} | {{ blockedTotals.editAdded }} | {{ blockedTotals.unarchived }})</span>
        </div>
        <div class="stats__row stats__row--red">{{ blockedTotals.deleted }} ({{ blockedTotals.editDeleted }} | {{ blockedTotals.archived }})
        </div>
        <div class="stats__row stats__row--blue">{{ blockedTotals.initial + blockedTotals.added - blockedTotals.deleted }}</div>
        <div class="stats__row stats__row--orange">
          {{ blockedTotals.added > blockedTotals.deleted ? '+' : '' }}{{ blockedTotals.added - blockedTotals.deleted }}
        </div>
      </div>

      <div class="stats__column stats__column--del" v-if="isBlockedShown" v-for="row of blockedRows" :key="row.id">
        <div class="stats__row">{{ row.name }}</div>
        <div class="stats__row stats__row--three">
          <span>{{ row.initial + row.added }}</span>
          <span>{{ row.initial }}</span>
          <span class="stats__row--green">{{ row.added }} ({{ row.created }} | {{ row.editAdded }} | {{ row.unarchived }})</span>
        </div>

        <div class="stats__row stats__row--red">{{ row.deleted }} ({{ row.editDeleted }} | {{ row.archived }})</div>
        <div class="stats__row stats__row--blue">{{ row.initial + row.added - row.deleted }}</div>
        <div class="stats__row stats__row--orange">
          {{ row.added > row.deleted ? '+' : '' }}{{ row.added - row.deleted }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import sharedMethods from '../Shared/sharedMethods'
export default {
  name: 'RegularStatistics',
  props: ['rows'],
  data() {
    return {
      isBlockedShown: false
    }
  },
  computed: {
    nonBlockedRows() {
      return this.rows.filter(r => !r.isBlocked)
    },
    blockedRows() {
      return this.rows.filter(r => r.isBlocked)
    },
    totals() {
      return sharedMethods.getRegularTotals(this.rows)
    },
    blockedTotals() {
      return sharedMethods.getRegularTotals(this.blockedRows)
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>