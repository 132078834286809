var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "screen__wrapper" }, [
    _c(
      "div",
      { staticClass: "stats__table" },
      [
        _vm._m(0),
        _vm.totals
          ? _c("div", { staticClass: "stats__column stats__column--light" }, [
              _c("div", { staticClass: "stats__row" }, [_vm._v("Разом АН")]),
              _c("div", { staticClass: "stats__row stats__row--three" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.totals.initial + _vm.totals.added))
                ]),
                _c("span", [_vm._v(_vm._s(_vm.totals.initial))]),
                _c("span", { staticClass: "stats__row--green" }, [
                  _vm._v(
                    _vm._s(_vm.totals.added) +
                      " (" +
                      _vm._s(_vm.totals.created) +
                      " | " +
                      _vm._s(_vm.totals.editAdded) +
                      " | " +
                      _vm._s(_vm.totals.unarchived) +
                      ")"
                  )
                ])
              ]),
              _c("div", { staticClass: "stats__row stats__row--red" }, [
                _vm._v(
                  _vm._s(_vm.totals.deleted) +
                    " (" +
                    _vm._s(_vm.totals.editDeleted) +
                    " | " +
                    _vm._s(_vm.totals.archived) +
                    ") "
                )
              ]),
              _c("div", { staticClass: "stats__row stats__row--blue" }, [
                _vm._v(
                  _vm._s(
                    _vm.totals.initial + _vm.totals.added - _vm.totals.deleted
                  )
                )
              ]),
              _c("div", { staticClass: "stats__row stats__row--orange" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.totals.added > _vm.totals.deleted ? "+" : "") +
                    _vm._s(_vm.totals.added - _vm.totals.deleted) +
                    " "
                )
              ])
            ])
          : _vm._e(),
        _vm._l(_vm.nonBlockedRows, function(row) {
          return _c("div", { key: row.id, staticClass: "stats__column" }, [
            _c("div", { staticClass: "stats__row" }, [
              _vm._v(_vm._s(row.name))
            ]),
            _c("div", { staticClass: "stats__row stats__row--three" }, [
              _c("span", [_vm._v(_vm._s(row.initial + row.added))]),
              _c("span", [_vm._v(_vm._s(row.initial))]),
              _c("span", { staticClass: "stats__row--green" }, [
                _vm._v(
                  _vm._s(row.added) +
                    " (" +
                    _vm._s(row.created) +
                    " | " +
                    _vm._s(row.editAdded) +
                    " | " +
                    _vm._s(row.unarchived) +
                    ")"
                )
              ])
            ]),
            _c("div", { staticClass: "stats__row stats__row--red" }, [
              _vm._v(
                _vm._s(row.deleted) +
                  " (" +
                  _vm._s(row.editDeleted) +
                  " | " +
                  _vm._s(row.archived) +
                  ")"
              )
            ]),
            _c("div", { staticClass: "stats__row stats__row--blue" }, [
              _vm._v(_vm._s(row.initial + row.added - row.deleted))
            ]),
            _c("div", { staticClass: "stats__row stats__row--orange" }, [
              _vm._v(
                " " +
                  _vm._s(row.added > row.deleted ? "+" : "") +
                  _vm._s(row.added - row.deleted) +
                  " "
              )
            ])
          ])
        }),
        _vm.blockedTotals
          ? _c(
              "div",
              {
                staticClass: "stats__column stats__column--del",
                on: {
                  click: function($event) {
                    _vm.isBlockedShown = !_vm.isBlockedShown
                  }
                }
              },
              [
                _c("div", { staticClass: "stats__row" }, [
                  _c("i", {
                    staticClass: "arrow",
                    class: {
                      down: _vm.isBlockedShown,
                      right: !_vm.isBlockedShown
                    },
                    staticStyle: { "margin-right": "5px" }
                  }),
                  _vm._v(" Разом видалені ")
                ]),
                _c("div", { staticClass: "stats__row stats__row--three" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.blockedTotals.initial + _vm.blockedTotals.added
                      )
                    )
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.blockedTotals.initial))]),
                  _c("span", { staticClass: "stats__row--green" }, [
                    _vm._v(
                      _vm._s(_vm.blockedTotals.added) +
                        " (" +
                        _vm._s(_vm.blockedTotals.created) +
                        " | " +
                        _vm._s(_vm.blockedTotals.editAdded) +
                        " | " +
                        _vm._s(_vm.blockedTotals.unarchived) +
                        ")"
                    )
                  ])
                ]),
                _c("div", { staticClass: "stats__row stats__row--red" }, [
                  _vm._v(
                    _vm._s(_vm.blockedTotals.deleted) +
                      " (" +
                      _vm._s(_vm.blockedTotals.editDeleted) +
                      " | " +
                      _vm._s(_vm.blockedTotals.archived) +
                      ") "
                  )
                ]),
                _c("div", { staticClass: "stats__row stats__row--blue" }, [
                  _vm._v(
                    _vm._s(
                      _vm.blockedTotals.initial +
                        _vm.blockedTotals.added -
                        _vm.blockedTotals.deleted
                    )
                  )
                ]),
                _c("div", { staticClass: "stats__row stats__row--orange" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.blockedTotals.added > _vm.blockedTotals.deleted
                          ? "+"
                          : ""
                      ) +
                      _vm._s(
                        _vm.blockedTotals.added - _vm.blockedTotals.deleted
                      ) +
                      " "
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._l(_vm.blockedRows, function(row) {
          return _vm.isBlockedShown
            ? _c(
                "div",
                {
                  key: row.id,
                  staticClass: "stats__column stats__column--del"
                },
                [
                  _c("div", { staticClass: "stats__row" }, [
                    _vm._v(_vm._s(row.name))
                  ]),
                  _c("div", { staticClass: "stats__row stats__row--three" }, [
                    _c("span", [_vm._v(_vm._s(row.initial + row.added))]),
                    _c("span", [_vm._v(_vm._s(row.initial))]),
                    _c("span", { staticClass: "stats__row--green" }, [
                      _vm._v(
                        _vm._s(row.added) +
                          " (" +
                          _vm._s(row.created) +
                          " | " +
                          _vm._s(row.editAdded) +
                          " | " +
                          _vm._s(row.unarchived) +
                          ")"
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "stats__row stats__row--red" }, [
                    _vm._v(
                      _vm._s(row.deleted) +
                        " (" +
                        _vm._s(row.editDeleted) +
                        " | " +
                        _vm._s(row.archived) +
                        ")"
                    )
                  ]),
                  _c("div", { staticClass: "stats__row stats__row--blue" }, [
                    _vm._v(_vm._s(row.initial + row.added - row.deleted))
                  ]),
                  _c("div", { staticClass: "stats__row stats__row--orange" }, [
                    _vm._v(
                      " " +
                        _vm._s(row.added > row.deleted ? "+" : "") +
                        _vm._s(row.added - row.deleted) +
                        " "
                    )
                  ])
                ]
              )
            : _vm._e()
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stats__column stats__column-title" }, [
      _c("div", { staticClass: "stats__row stats__row-title" }, [
        _vm._v("Агент")
      ]),
      _c(
        "div",
        { staticClass: "stats__row stats__row-title stats__row--three" },
        [
          _c("span", [_vm._v("Разом")]),
          _c("span", [_vm._v("Було")]),
          _c("span", { staticClass: "stats__row--green" }, [
            _vm._v("Нові"),
            _c("br"),
            _c("small", [
              _vm._v(" ("),
              _c(
                "span",
                {
                  staticClass: "cursor--default",
                  attrs: { title: "Створено" }
                },
                [_vm._v("С")]
              ),
              _vm._v(" | "),
              _c(
                "span",
                { staticClass: "cursor--default", attrs: { title: "Змінено" } },
                [_vm._v("З")]
              ),
              _vm._v(" | "),
              _c(
                "span",
                {
                  staticClass: "cursor--default",
                  attrs: { title: "Відновлено" }
                },
                [_vm._v("В")]
              ),
              _vm._v(") ")
            ])
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "stats__row stats__row-title stats__row--red" },
        [
          _vm._v("Видалено"),
          _c("br"),
          _c("small", [
            _vm._v(" ("),
            _c(
              "span",
              { staticClass: "cursor--default", attrs: { title: "Змінено" } },
              [_vm._v("З")]
            ),
            _vm._v(" | "),
            _c(
              "span",
              {
                staticClass: "cursor--default",
                attrs: { title: "Архівовано" }
              },
              [_vm._v("А")]
            ),
            _vm._v(") ")
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "stats__row stats__row-title stats__row--blue" },
        [_vm._v("Стало")]
      ),
      _c(
        "div",
        { staticClass: "stats__row stats__row-title stats__row--orange" },
        [_vm._v("+/-")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }