var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "screen__wrapper" }, [
    _c(
      "div",
      { staticClass: "stats__table stats__table--second stats__table-ctr" },
      [
        _vm._m(0),
        _c("div", { staticClass: "stats__column stats__column--light" }, [
          _c("div", { staticClass: "stats__row" }, [_vm._v("Разом АН")]),
          _c("div", { staticClass: "stats__row stats__row--fifts" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.totals.initialNED + _vm.totals.addedNED))
            ]),
            _c("span", [_vm._v(_vm._s(_vm.totals.initialNED))]),
            _c("span", { staticClass: "stats__row--green" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.totals.addedNED) +
                  " (" +
                  _vm._s(_vm.totals.createdNED) +
                  " | " +
                  _vm._s(_vm.totals.editAddedNED) +
                  " | " +
                  _vm._s(_vm.totals.unarchivedNED) +
                  ") "
              )
            ]),
            _c("span", { staticClass: "stats__row--red" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.totals.deletedNED) +
                  " (" +
                  _vm._s(_vm.totals.editDeletedNED) +
                  " | " +
                  _vm._s(_vm.totals.archivedNED) +
                  ") "
              )
            ]),
            _c("span", { staticClass: "stats__row--blue" }, [
              _vm._v(
                _vm._s(
                  _vm.totals.initialNED +
                    _vm.totals.addedNED -
                    _vm.totals.deletedNED
                )
              )
            ])
          ]),
          _c("div", { staticClass: "stats__row stats__row--fifts" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.totals.initialED + _vm.totals.addedED))
            ]),
            _c("span", [_vm._v(_vm._s(_vm.totals.initialED))]),
            _c("span", { staticClass: "stats__row--green" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.totals.addedED) +
                  " (" +
                  _vm._s(_vm.totals.createdED) +
                  " | " +
                  _vm._s(_vm.totals.editAddedED) +
                  " | " +
                  _vm._s(_vm.totals.unarchivedED) +
                  ") "
              )
            ]),
            _c("span", { staticClass: "stats__row--red" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.totals.deletedED) +
                  " (" +
                  _vm._s(_vm.totals.editDeletedED) +
                  " | " +
                  _vm._s(_vm.totals.archivedED) +
                  ") "
              )
            ]),
            _c("span", { staticClass: "stats__row--blue" }, [
              _vm._v(
                _vm._s(
                  _vm.totals.initialED +
                    _vm.totals.addedED -
                    _vm.totals.deletedED
                )
              )
            ])
          ])
        ]),
        _vm._l(_vm.nonBlockedRows, function(row) {
          return _c(
            "div",
            {
              key: row.id,
              staticClass: "stats__column",
              class: { "stats__column--del": row.isBlocked }
            },
            [
              _c("div", { staticClass: "stats__row" }, [
                _vm._v(_vm._s(row.name))
              ]),
              _c("div", { staticClass: "stats__row stats__row--fifts" }, [
                _c("span", [_vm._v(_vm._s(row.initialNED + row.addedNED))]),
                _c("span", [_vm._v(_vm._s(row.initialNED))]),
                _c("span", { staticClass: "stats__row--green" }, [
                  _vm._v(
                    " " +
                      _vm._s(row.addedNED) +
                      " (" +
                      _vm._s(row.createdNED) +
                      " | " +
                      _vm._s(row.editAddedNED) +
                      " | " +
                      _vm._s(row.unarchivedNED) +
                      ") "
                  )
                ]),
                _c("span", { staticClass: "stats__row--red" }, [
                  _vm._v(
                    " " +
                      _vm._s(row.deletedNED) +
                      " (" +
                      _vm._s(row.editDeletedNED) +
                      " | " +
                      _vm._s(row.archivedNED) +
                      ") "
                  )
                ]),
                _c("span", { staticClass: "stats__row--blue" }, [
                  _vm._v(_vm._s(row.initialNED + row.addedNED - row.deletedNED))
                ])
              ]),
              _c("div", { staticClass: "stats__row stats__row--fifts" }, [
                _c("span", [_vm._v(_vm._s(row.initialED + row.addedED))]),
                _c("span", [_vm._v(_vm._s(row.initialED))]),
                _c("span", { staticClass: "stats__row--green" }, [
                  _vm._v(
                    " " +
                      _vm._s(row.addedED) +
                      " (" +
                      _vm._s(row.createdED) +
                      " | " +
                      _vm._s(row.editAddedED) +
                      " | " +
                      _vm._s(row.unarchivedED) +
                      ") "
                  )
                ]),
                _c("span", { staticClass: "stats__row--red" }, [
                  _vm._v(
                    " " +
                      _vm._s(row.deletedED) +
                      " (" +
                      _vm._s(row.editDeletedED) +
                      " | " +
                      _vm._s(row.archivedED) +
                      ") "
                  )
                ]),
                _c("span", { staticClass: "stats__row--blue" }, [
                  _vm._v(_vm._s(row.initialED + row.addedED - row.deletedED))
                ])
              ])
            ]
          )
        }),
        _vm.blockedTotals
          ? _c(
              "div",
              {
                staticClass: "stats__column stats__column--del",
                on: {
                  click: function($event) {
                    _vm.isBlockedShown = !_vm.isBlockedShown
                  }
                }
              },
              [
                _c("div", { staticClass: "stats__row" }, [
                  _c("i", {
                    staticClass: "arrow",
                    class: {
                      down: _vm.isBlockedShown,
                      right: !_vm.isBlockedShown
                    },
                    staticStyle: { "margin-right": "5px" }
                  }),
                  _vm._v(" Разом видалені ")
                ]),
                _c("div", { staticClass: "stats__row stats__row--fifts" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.blockedTotals.initialNED +
                          _vm.blockedTotals.addedNED
                      )
                    )
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.blockedTotals.initialNED))]),
                  _c("span", { staticClass: "stats__row--green" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.blockedTotals.addedNED) +
                        " (" +
                        _vm._s(_vm.blockedTotals.createdNED) +
                        " | " +
                        _vm._s(_vm.blockedTotals.editAddedNED) +
                        " | " +
                        _vm._s(_vm.blockedTotals.unarchivedNED) +
                        ") "
                    )
                  ]),
                  _c("span", { staticClass: "stats__row--red" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.blockedTotals.deletedNED) +
                        " (" +
                        _vm._s(_vm.blockedTotals.editDeletedNED) +
                        " | " +
                        _vm._s(_vm.blockedTotals.archivedNED) +
                        ") "
                    )
                  ]),
                  _c("span", { staticClass: "stats__row--blue" }, [
                    _vm._v(
                      _vm._s(
                        _vm.blockedTotals.initialNED +
                          _vm.blockedTotals.addedNED -
                          _vm.blockedTotals.deletedNED
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "stats__row stats__row--fifts" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.blockedTotals.initialED + _vm.blockedTotals.addedED
                      )
                    )
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.blockedTotals.initialED))]),
                  _c("span", { staticClass: "stats__row--green" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.blockedTotals.addedED) +
                        " (" +
                        _vm._s(_vm.blockedTotals.createdED) +
                        " | " +
                        _vm._s(_vm.blockedTotals.editAddedED) +
                        " | " +
                        _vm._s(_vm.blockedTotals.unarchivedED) +
                        ") "
                    )
                  ]),
                  _c("span", { staticClass: "stats__row--red" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.blockedTotals.deletedED) +
                        " (" +
                        _vm._s(_vm.blockedTotals.editDeletedED) +
                        " | " +
                        _vm._s(_vm.blockedTotals.archivedED) +
                        ") "
                    )
                  ]),
                  _c("span", { staticClass: "stats__row--blue" }, [
                    _vm._v(
                      _vm._s(
                        _vm.blockedTotals.initialED +
                          _vm.blockedTotals.addedED -
                          _vm.blockedTotals.deletedED
                      )
                    )
                  ])
                ])
              ]
            )
          : _vm._e(),
        _vm._l(_vm.blockedRows, function(row) {
          return _vm.isBlockedShown
            ? _c(
                "div",
                {
                  key: row.id,
                  staticClass: "stats__column stats__column--del"
                },
                [
                  _c("div", { staticClass: "stats__row" }, [
                    _vm._v(_vm._s(row.name))
                  ]),
                  _c("div", { staticClass: "stats__row stats__row--fifts" }, [
                    _c("span", [_vm._v(_vm._s(row.initialNED + row.addedNED))]),
                    _c("span", [_vm._v(_vm._s(row.initialNED))]),
                    _c("span", { staticClass: "stats__row--green" }, [
                      _vm._v(
                        " " +
                          _vm._s(row.addedNED) +
                          " (" +
                          _vm._s(row.createdNED) +
                          " | " +
                          _vm._s(row.editAddedNED) +
                          " | " +
                          _vm._s(row.unarchivedNED) +
                          ") "
                      )
                    ]),
                    _c("span", { staticClass: "stats__row--red" }, [
                      _vm._v(
                        " " +
                          _vm._s(row.deletedNED) +
                          " (" +
                          _vm._s(row.editDeletedNED) +
                          " | " +
                          _vm._s(row.archivedNED) +
                          ") "
                      )
                    ]),
                    _c("span", { staticClass: "stats__row--blue" }, [
                      _vm._v(
                        _vm._s(row.initialNED + row.addedNED - row.deletedNED)
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "stats__row stats__row--fifts" }, [
                    _c("span", [_vm._v(_vm._s(row.initialED + row.addedED))]),
                    _c("span", [_vm._v(_vm._s(row.initialED))]),
                    _c("span", { staticClass: "stats__row--green" }, [
                      _vm._v(
                        " " +
                          _vm._s(row.addedED) +
                          " (" +
                          _vm._s(row.createdED) +
                          " | " +
                          _vm._s(row.editAddedED) +
                          " | " +
                          _vm._s(row.unarchivedED) +
                          ") "
                      )
                    ]),
                    _c("span", { staticClass: "stats__row--red" }, [
                      _vm._v(
                        " " +
                          _vm._s(row.deletedED) +
                          " (" +
                          _vm._s(row.editDeletedED) +
                          " | " +
                          _vm._s(row.archivedED) +
                          ") "
                      )
                    ]),
                    _c("span", { staticClass: "stats__row--blue" }, [
                      _vm._v(
                        _vm._s(row.initialED + row.addedED - row.deletedED)
                      )
                    ])
                  ])
                ]
              )
            : _vm._e()
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stats__column stats__column-title" }, [
      _c("div", { staticClass: "stats__row stats__row-title" }, [
        _vm._v("Агент")
      ]),
      _c(
        "div",
        { staticClass: "stats__row stats__row-title stats__row--fifts" },
        [
          _c("span", [_vm._v("Разом НЕД")]),
          _c("span", [_vm._v("Було")]),
          _c("span", { staticClass: "stats__row--green" }, [
            _vm._v("Нові"),
            _c("br"),
            _c("small", [
              _vm._v(" ("),
              _c(
                "span",
                {
                  staticClass: "cursor--default",
                  attrs: { title: "Створено" }
                },
                [_vm._v("С")]
              ),
              _vm._v(" | "),
              _c(
                "span",
                { staticClass: "cursor--default", attrs: { title: "Змінено" } },
                [_vm._v("З")]
              ),
              _vm._v(" | "),
              _c(
                "span",
                {
                  staticClass: "cursor--default",
                  attrs: { title: "Відновлено" }
                },
                [_vm._v("В")]
              ),
              _vm._v(") ")
            ])
          ]),
          _c("span", { staticClass: "stats__row--red" }, [
            _vm._v("Видалено"),
            _c("br"),
            _c("small", [
              _vm._v(" ("),
              _c(
                "span",
                { staticClass: "cursor--default", attrs: { title: "Змінено" } },
                [_vm._v("З")]
              ),
              _vm._v(" |"),
              _c(
                "span",
                {
                  staticClass: "cursor--default",
                  attrs: { title: "Архівовано" }
                },
                [_vm._v("А")]
              ),
              _vm._v(") ")
            ])
          ]),
          _c("span", { staticClass: "stats__row--blue" }, [_vm._v("Стало")])
        ]
      ),
      _c(
        "div",
        { staticClass: "stats__row stats__row-title stats__row--fifts" },
        [
          _c("span", [_vm._v("Разом ЕД")]),
          _c("span", [_vm._v("Було")]),
          _c("span", { staticClass: "stats__row--green" }, [
            _vm._v("Нові"),
            _c("br"),
            _c("small", [
              _vm._v(" ("),
              _c(
                "span",
                {
                  staticClass: "cursor--default",
                  attrs: { title: "Створено" }
                },
                [_vm._v("С")]
              ),
              _vm._v(" | "),
              _c(
                "span",
                { staticClass: "cursor--default", attrs: { title: "Змінено" } },
                [_vm._v("З")]
              ),
              _vm._v(" | "),
              _c(
                "span",
                {
                  staticClass: "cursor--default",
                  attrs: { title: "Відновлено" }
                },
                [_vm._v("В")]
              ),
              _vm._v(") ")
            ])
          ]),
          _c("span", { staticClass: "stats__row--red" }, [
            _vm._v("Видалено"),
            _c("br"),
            _c("small", [
              _vm._v(" ("),
              _c(
                "span",
                { staticClass: "cursor--default", attrs: { title: "Змінено" } },
                [_vm._v("З")]
              ),
              _vm._v(") |"),
              _c(
                "span",
                {
                  staticClass: "cursor--default",
                  attrs: { title: "Архівовано" }
                },
                [_vm._v("А")]
              ),
              _vm._v(") ")
            ])
          ]),
          _c("span", { staticClass: "stats__row--blue" }, [_vm._v("Стало")])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }